var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}},model:{value:(_vm.form_valid),callback:function ($$v) {_vm.form_valid=$$v},expression:"form_valid"}},[_c('h3',{staticClass:"text-h3 mb-6"},[_vm._v("Предлагаемые продукты в проект")]),_c('v-btn',{staticClass:"mb-6",attrs:{"color":"accent darken-4","depressed":""},on:{"click":_vm.show_add_product_modal}},[_vm._v(" Добавить продукт в проект ")]),_c('SimpleDialog',{attrs:{"max-width":"970"},on:{"close":_vm.close_add_product_modal},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Добавление номенклатуры в проект ")]},proxy:true},{key:"text",fn:function(){return [_c('Filters',{attrs:{"hide_in_stock":""},on:{"filter_update":_vm.load_and_upd_prods}}),_c('Table',{attrs:{"headers":_vm.products_model_headers,"items":_vm.all_products,"loading":_vm.is_prods_loading,"hide-default-footer":"","custom-sort":_vm.prevent_sort,"no-data-text":"Товары, удовлетворяющие выбранным параметрам, отсутствуют","not_resizeble":true},scopedSlots:_vm._u([{key:"item.nomenclature",fn:function(ref){
var item = ref.item;
return [_c('div',{style:(_vm.is_mobile ? 'width:100px' : '')},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(
                    ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({class:_vm.$style.nomenclatureName},'div',attrs,false),on),[_c('span',{class:[_vm.$style.nomenclatureText, 'overflow-text']},[_vm._v(" "+_vm._s(item.nomenclature)+" ")])])]}}],null,true)},[_vm._v(" "+_vm._s(item.nomenclature)+" ")])],1)]}},{key:"item.category_name",fn:function(ref){
                    var item = ref.item;
return [_c('div',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(item.category_name)+" ")]),(item.product_software_type)?_c('div',{class:_vm.$style.productCategoryDesc},[_vm._v(" "+_vm._s(item.product_software_type.name)+" ")]):_vm._e()]}},{key:"item.price",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency_rub")(item.prices.actual.base.rub))+" ")]}},{key:"item.action",fn:function(ref){
                    var item = ref.item;
return [_c('v-btn',{staticClass:"mt-2 mb-2",attrs:{"color":"accent darken-4","depressed":"","type":"submit"},on:{"click":function($event){return _vm.add_product(item)}}},[_vm._v(" Добавить ")])]}}],null,true)})]},proxy:true},{key:"actions",fn:function(){return [_c('Pagination',{class:_vm.$style.pagination,attrs:{"pagination_props":_vm.pagination_props,"hide_page_size":""},on:{"change_page":function($event){return _vm.upd_product_pagination('current_page', $event)},"page_size_changed":function($event){return _vm.upd_product_pagination('limit', $event)}}})]},proxy:true}]),model:{value:(_vm.add_product_modal),callback:function ($$v) {_vm.add_product_modal=$$v},expression:"add_product_modal"}}),_c('Table',{attrs:{"headers":_vm.cols,"items":_vm.products,"no-data-text":"Продукты не добавлены","hide-default-footer":"","not_resizeble":true},scopedSlots:_vm._u([{key:"item.catalog_price",fn:function(ref){
                    var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_vm._v(" "+_vm._s(_vm._f("currency_rub")(item.catalog_price))+" ")])]}},{key:"item.client_price",fn:function(ref){
                    var item = ref.item;
return [_c('tr',{staticClass:"py-2"},[_c('td',[(item.client_price)?_c('div',[_vm._v(_vm._s(item.client_price))]):_c('span',{class:_vm.$style.text},[_vm._v("Укажите цену")])]),_c('td',[_c('v-btn',{staticClass:"ml-2",attrs:{"icon":"","small":"","color":"accent darken-4","title":"Редактировать"},on:{"click":function($event){return _vm.show_price_set_modal(item, true)}}},[_c('v-icon',[_vm._v("mdi-pencil-outline")])],1)],1)])]}},{key:"item.desired_price",fn:function(ref){
                    var item = ref.item;
return [_c('tr',{staticClass:"py-2"},[_c('td',[(item.desired_price)?_c('div',[_vm._v(_vm._s(item.desired_price))]):_c('span',{class:_vm.$style.text},[_vm._v("Укажите цену")])]),_c('td',[_c('v-btn',{staticClass:"ml-2",attrs:{"icon":"","small":"","color":"accent darken-4","title":"Редактировать"},on:{"click":function($event){return _vm.show_price_set_modal(item, false)}}},[_c('v-icon',[_vm._v("mdi-pencil-outline")])],1)],1)])]}},{key:"item.action",fn:function(ref){
                    var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_c('tr',[_c('v-btn',{attrs:{"icon":"","color":"secondary darken-4","title":"Удалить"},on:{"click":function($event){return _vm.remove_item(item)}}},[_c('v-icon',[_vm._v("mdi-delete-outline")])],1)],1)])]}},{key:"item.quantity",fn:function(ref){
                    var item = ref.item;
return [_c('div',{on:{"click":function($event){$event.stopPropagation();}}},[_c('TextField',{class:_vm.$style.quantityInput,attrs:{"hide-details":"","dense":"","type":"number","background-color":"#fff","clearable":false,"max":_vm.max_quantity,"min":1},on:{"input":function($event){return _vm.upd_prod_quantity(item, $event)}},model:{value:(item.quantity),callback:function ($$v) {_vm.$set(item, "quantity", $$v)},expression:"item.quantity"}})],1)]}}],null,true)}),_c('div',{staticClass:"mt-6"},[_c('v-divider',{staticClass:"mb-6"}),_c('div',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"mr-auto",attrs:{"depressed":""},on:{"click":_vm.close}},[_vm._v(" Отменить ")]),_c('v-btn',{attrs:{"depressed":"","disabled":!_vm.form_valid},on:{"click":_vm.go_back}},[_vm._v(" Назад ")]),_c('v-tooltip',{attrs:{"color":"primary darken-3","bottom":"","open-delay":300,"disabled":_vm.is_valid_table()},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-btn',{staticClass:"ml-4",attrs:{"color":"accent darken-4","depressed":"","disabled":!_vm.is_valid_table(),"type":"submit"}},[_vm._v(" Продолжить ")])],1)]}}])},[_c('span',[_vm._v("Необходимо указать цену для клиента и запрашиваемую цену для товаров добавленных в проект")])])],1)],1)],1),_c('SimpleDialog',{attrs:{"max-width":"480","bts_align":'center'},on:{"close":_vm.close_set_price_modal},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Укажите цену ")]},proxy:true},{key:"text",fn:function(){return [_c('div',[(_vm.is_client_price)?_c('p',{staticClass:"mb-2"},[_vm._v(" Укажите цену, по которой планируете реализацию товара клиенту. ")]):_c('p',{staticClass:"mb-2"},[_vm._v("Укажите цену, запрашиваемую у АТОЛ.")]),(_vm.is_client_price)?_c('TextField',{attrs:{"label":"Цена для клиента","rules":[
            function (v) { return _vm.is_require(v) || _vm.require_message; }
          ],"type":"number","hide_btns":""},model:{value:(_vm.set_client_price),callback:function ($$v) {_vm.set_client_price=$$v},expression:"set_client_price"}}):_c('TextField',{attrs:{"label":"Запрашиваемая цена","rules":[
            function (v) { return _vm.is_require(v) || _vm.require_message; }
          ],"type":"number","hide_btns":""},model:{value:(_vm.set_desired_price),callback:function ($$v) {_vm.set_desired_price=$$v},expression:"set_desired_price"}})],1)]},proxy:true},{key:"actions",fn:function(){return [(_vm.is_client_price)?_c('v-btn',{staticClass:"mr-2",attrs:{"color":"accent darken-4","depressed":"","disabled":_vm.set_client_price === null},on:{"click":function($event){return _vm.save_client_price(_vm.editable_item)}}},[_vm._v(" Добавить ")]):_c('v-btn',{staticClass:"mr-2",attrs:{"color":"accent darken-4","depressed":"","disabled":_vm.set_desired_price === null},on:{"click":function($event){return _vm.save_partner_price(_vm.editable_item)}}},[_vm._v(" Добавить ")]),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.close_set_price_modal}},[_vm._v(" Отменить ")])]},proxy:true}]),model:{value:(_vm.set_price_modal),callback:function ($$v) {_vm.set_price_modal=$$v},expression:"set_price_modal"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }